import { Component,OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient,HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  url=environment.apiUrl;
  token='';
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': this.token});
  options = { headers: this.headers };
  subdomain='';
  logo = '';
  socmed = '';
  tv='';
  cetak='';
  clientid='';
  email='';
  Wordcloud='';
  title = 'digivla-dcc';
  projectid=""
  tokenuse = ""

  ngOnInit(){
    // setTimeout(()=>{ 
      // console.log(window.location.hostname);
    this.subdomain = window.location.hostname.split('.')[0];
    for (let i =0;i < environment.user.length; i++){
      if (this.subdomain == environment.user[i].id){
        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Token '+environment.user[i].token});
        this.projectid = environment.user[i].projectid;
        this.tokenuse = environment.user[i].tokenuse;
        this.options = {headers:this.headers};
        this.logo = environment.user[i].logo;
        this.socmed = environment.user[i].socmed;
        this.tv = environment.user[i].tv;
        this.cetak = environment.user[i].cetak;
        var className = document.body;
        this.email = environment.user[i].email;
        this.Wordcloud = environment.user[i].Wordcloud;
        this.clientid = environment.user[i].clientid;
        // console.log(window.location.hostname + '/'+environment.user[i].background);        
        className.style.backgroundImage =  'url('+environment.user[i].background+')';
      }
    }
      
    // },1000);     

  }
}
