import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Panel6Component } from './Panel6/Panel6.component';
// import { Panel5Component } from './Panel5/Panel5.component';
import { Panel5Module } from './Panel5/Panel5.module'
import { ChartModule } from 'angular-highcharts';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { AuthGuard } from "./guard/auth.guard";
// import { MedsosModuleComponent } from './medsos-module/medsos-module.component';

//digivla module
// import { CategoryModuleModule } from './CategoryModule/CategoryModule.module'
// import { MediaModuleModule } from './MediaModule/MediaModule.module'
// import { TimePickerModuleModule } from './TimePickerModule/TimePickerModule.module'
// import { ArticlesModuleModule } from './ArticlesModule/ArticlesModule.module'


@NgModule({
   declarations: [
      AppComponent,
      // MedsosModuleComponent,
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      CommonModule,
   ],
   providers: [AuthGuard], 
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
