import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./guard/auth.guard";
const routes: Routes = [
  { path: "", redirectTo: "panel5" , pathMatch: "full"},
  {
    path: "panel1",
    loadChildren: "./Panel1/Panel1.module#Panel1Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel2",
    loadChildren: "./Panel2/Panel2.module#Panel2Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel3",
    loadChildren: "./Panel3/Panel3.module#Panel3Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel4",
    loadChildren: "./Panel4/Panel4.module#Panel4Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel5",
    loadChildren: "./Panel5/Panel5.module#Panel5Module",
    //canActivate: [AuthGuard]
  },
  {
    path: "panel6",
    loadChildren: "./Panel6/Panel6.module#Panel6Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel7",
    loadChildren: "./Panel7/Panel7.module#Panel7Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel8",
    loadChildren: "./Panel8/Panel8.module#Panel8Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel9",
    loadChildren: "./Panel9/Panel9.module#Panel9Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel10",
    loadChildren: "./Panel10/Panel10.module#Panel10Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panel11",
    loadChildren: "./Panel11/Panel11.module#Panel11Module",
    canActivate: [AuthGuard]
  },
  {
    path: "panelDesc",
    loadChildren: "./PanelDesc/PanelDesc.module#PanelDescModule",
    canActivate: [AuthGuard]
  },
  {
    path: "tweetdeck",
    loadChildren: "./tweetdeck/tweetdeck.module#TweetdeckModule",
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
