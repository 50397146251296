// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //live prod
  apiUrl: 'https://api.digivla.id/api/v1/',
  user: [{
    id: 'kemlu',
    token: 'fb9f251a5a0067467d5211dd3734035972ef192e',
    background: 'assets/img/backgrounds/default@3840x2160.jpg',
    socmed: 'kemlu',
    logo: 'assets/img/kemlu.png',
    tv: '3982',
    cetak: '5110',
    Wordcloud: 'kemlu-v2',
    email: 'rr@antara-insight.id',
    clientid: 'kiuem',
    projectid: 'bWVkbW9uLTcw',
    tokenuse: 'JDJ5JDEwJC4vSnVyeXNXVDZ3U0QwRTZQNmZjL2UxQmhNamR4TnM2Ni9UeEk2OWNra2d4cW5Ib1BjVDJ5'
  },
  {
    id: 'sumbar',
    token: 'd382b1d09e9c282a5b99a0891ad665938cbaa878',
    background: 'assets/img/backgrounds/default@3840x2160.jpg',
    socmed: 'sumbar',
    logo: 'assets/img/sumbar.png',
    tv: '5532',
    cetak: '5491',
    Wordcloud: 'sumbar',
    email: 'rr@antara-insight.id',
    clientid: 'banbj',
    projectid: 'bWVkbW9uLTcw',
    tokenuse: 'JDJ5JDEwJC4vSnVyeXNXVDZ3U0QwRTZQNmZjL2UxQmhNamR4TnM2Ni9UeEk2OWNra2d4cW5Ib1BjVDJ5'
  },
  {
    id: 'bankbjb',
    token: 'f63c7224cb67b744195788826c28873558c492b3',
    background: 'assets/img/backgrounds/default@3840x2160.jpg',
    socmed: 'bankbjb',
    logo: 'assets/img/bankbjb.png',
    tv: '4153',
    cetak: '4142',
    Wordcloud: 'bankbjb',
    email: 'rr@antara-insight.id',
    clientid: 'banbj',
    projectid: 'bWVkbW9uLTcw',
    tokenuse: 'JDJ5JDEwJC4vSnVyeXNXVDZ3U0QwRTZQNmZjL2UxQmhNamR4TnM2Ni9UeEk2OWNra2d4cW5Ib1BjVDJ5'
  },
    {
    id: 'jica',
    token: 'da76f2d2ad41929e7a93e23c698d689683d7ab00',
    background: 'assets/img/backgrounds/default@3840x2160.jpg',
    socmed: 'kemlu',
    logo: 'assets/img/jica.png',
    tv: '5138',
    cetak: '5137',
    Wordcloud: 'kemlu-v2',
    email: 'rr@antara-insight.id',
    clientid: 'ganna',
    projectid: 'bWVkbW9aLTcw',
    tokenuse: 'JDJ5JDEwJC4vSnCyeXNXVDZ3U0QwRTZQNmZjL2UxQmhNamR4TnM2Ni9UeEk2OWNra2d4cW5Ib1BjVDJ5'
  },
  {
    id: 'localhost',
    token: '741c8b17eb7beb7a6e67f31adbaf89ce03c611b8',
    background: 'assets/img/backgrounds/default@3840x2160.jpg',
    socmed: 'kemlu',
    logo: 'assets/img/bni.png',
    tv: '5450',
    cetak: '5448',
    Wordcloud: 'kemlu-v2',
    email: 'rr@antara-insight.id',
    clientid: 'gaina',
    projectid: 'bWVkbW9uLTcw',
    tokenuse: 'JDJ5JDEwJC4vSnVyeXNXVDZ3U0QwRTZQNmZjL2UxQmhNamR4TnM2Ni9UeEk2OWNra2d4cW5Ib1BjVDJ5'
  },
  {
    id: 'dashboardbni',
    token: '8d6003cf6c71f522e120a7cd702ff3abbe74a9a7',
    background: 'assets/img/backgrounds/default@3840x2160.jpg',
    socmed: 'bni',
    logo: 'assets/img/BNI_Logo.png',
    tv: '6642',
    cetak: '6205',
    Wordcloud: 'dashboardbni',
    email: 'tirta@tirto.id',
    clientid: 'knaao',
    projectid: 'bWVkbW9uLTcw',
    tokenuse: 'JDJ5JDEwJC4vSnVyeXNXVDZ3U0QwRTZQNmZjL2UxQmhNamR4TnM2Ni9UeEk2OWNra2d4cW5Ib1BjVDJ5'
  },
  {
    id: 'skkmigas',
    token: '551bad6277884c108300f8ad74a1c2d5c0760897',
    background: 'assets/img/backgrounds/default@3840x2160.jpg',
    socmed: 'skkmigas',
    logo: 'assets/img/skkmigas.png',
    tv: '3576',
    cetak: '1',
    Wordcloud: 'skkmigas',
    email: 'rr@antara-insight.id',
    clientid: 'pzydc',
    projectid: 'bWVkbW9uLTcw',
    tokenuse: 'JDJ5JDEwJC4vSnVyeXNXVDZ3U0QwRTZQNmZjL2UxQmhNamR4TnM2Ni9UeEk2OWNra2d4cW5Ib1BjVDJ5'
  },
  {
    id: 'bni',
    token: '741c8b17eb7beb7a6e67f31adbaf89ce03c611b8',
    background: 'assets/img/backgrounds/default@3840x2160.jpg',
    socmed: 'bni',
    logo: 'assets/img/bni.png',
    tv: '5450',
    cetak: '5448',
    Wordcloud: 'bni-v2',
    email: 'rr@antara-insight.id',
    clientid: 'gaina',
    projectid: 'bWVkbW9uLTcw',
    tokenuse: 'JDJ5JDEwJC4vSnVyeXNXVDZ3U0QwRTZQNmZjL2UxQmhNamR4TnM2Ni9UeEk2OWNra2d4cW5Ib1BjVDJ5'
  },
  ]
};
